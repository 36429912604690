<div class="search-filters">
	<div class="search-filters--form" >
		<app-calendar [(selectedStartDate$)]="selectedStartDate$" [(selectedEndDate$)]="selectedEndDate$" />
		<p-iconField iconPosition="right">
			<p-inputIcon (click)="visibleMap()" styleClass="pi pi-map"></p-inputIcon>
			<input (input)="searchByAddress($event)" type="text" pInputText placeholder="Местоположение" />
		</p-iconField>
		<div class="submit">
			<p-button
				icon="pi pi-sliders-h"
				severity="info"
				class="additional-1"
			/>
			<p-button severity="info" class="additional-2">Больше настроек</p-button>
			<p-button type="submit" (click)="submitSearchEvent()">Найти</p-button>
		</div>
	</div>
</div>
<div>
	<ng-container #modalForm></ng-container>
</div>
