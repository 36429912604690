<header>
	<div class="nav">
		<p class="nav__logo">Поребрик</p>
		<div class="nav__right-side" [ngClass]="{ 'dropdown-active': isDropdownVisible }">
			<p-button>Создать событие</p-button>
			@if (isAuth) {
				<p-button
					class="nav__profile-icon"
					icon="pi pi-user"
					[rounded]="true"
					severity="secondary"
					[outlined]="true"
					(click)="toggleDropdown()">
				</p-button>
				<div class="dropdown-menu">
					<ul>
						<li><a href="#">Профиль</a></li>
						<li><a href="#">Друзья</a></li>
						<li><a href="#">Подписки</a></li>
						<li><a href="#">Выход</a></li>
					</ul>
				</div>
			} @else {
				<p-button
					class="nav__profile-icon"
					icon="pi pi-sign-in"
					[rounded]="true"
					severity="secondary"
					[outlined]="true"
					(click)="showDialog()">
				</p-button>
			}
		</div>
	</div>
</header>

<div>
	<ng-container #modalForm></ng-container>
</div>

<div class="bottom-nav">
	<div class="nav-item">
		<i class="pi pi-home" style="font-size: 1.5rem;"></i>
		<span>Главная</span>
	</div>
	<div class="nav-item">
		<i class="pi pi-plus-circle" style="font-size: 1.5rem;"></i>
		<span>Добавить</span>
	</div>
	@if (isAuth) {
		<div class="nav-item">
			<i class="pi pi-user" style="font-size: 1.5rem;"></i>
			<span>Профиль</span>
		</div>
	} @else {
		<div class="nav-item" (click)="showDialog()">
			<i class="pi pi-sign-in" style="font-size: 1.5rem;"></i>
			<span>Войти</span>
		</div>
	}
</div>

<p-dialog header="Форма входа" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
	<app-sign-in></app-sign-in>
</p-dialog>
