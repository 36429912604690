import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from '../modal-base';
import { ClockComponent } from '../../components/clock/clock';

@Component({
	templateUrl: './clock-modal.html',
	standalone: true,
	styleUrls: ['./clock.style.scss'],
})
export class ClockModalComponent
	extends ModalComponent
	implements AfterViewInit
{
	@ViewChild('ref', { read: ViewContainerRef }) ref!: ViewContainerRef;

	@ViewChild('close') closeBtn!: ElementRef;

	@Input() eventTime!: number;

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	ngAfterViewInit(): void {
		const component = this.ref.createComponent(ClockComponent);
		component.instance.eventTime = this.eventTime;
		this.cd.detectChanges();
		component.instance.emitTime.subscribe({
			next: (val: string) => {
				this.closeModal.emit(val);
			},
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	destroyModal() {
		this.closeBtn.nativeElement.style.display = 'none';
	}
}
