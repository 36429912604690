@if (userInfo.length > 0) {
	<div class="friends-container">
		<article *ngFor="let item of userInfo" class=friends-container__card>
			<div class="friends-container__card-logo"><img src="../../../../../assets/images/gray.png" alt=""></div>
			<div class="friends-container__card-content">
				{{ item.name }}
				<br>
				@if (item.can_change && !item.approve) {
					<span>В ожидании ответа</span>
				}
				@if (!item.can_change && !item.approve) {
					<span>Заявка отправлена</span>
				}
			</div>
			<div class="friends-container__card-btn">
				<p-button label="Подробнее" [outlined]="true" (click)="navigateToDetail(item.user_id)" />
				<br>
				<p-button label="Скрыть" severity="secondary" [outlined]="true" (click)="navigateToDetail(item.user_id)" />
			</div>
		</article>
	</div>
} @else {
	<div class="friends-container">
		<h3>У вас пока нет друзей</h3>
	</div>
}
