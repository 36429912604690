import {
	ComponentRef,
	EventEmitter,
	Injectable,
	NgZone,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from '../../shared/modal/modal-base';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	protected componentRef!: ComponentRef<any>;

	constructor(private zone: NgZone) {}

	public openModal(
		entryComponent: ViewContainerRef,
		options?: { title?: string; [key: string]: any },
		modalComponent?: Type<any>
	): EventEmitter<unknown> {
		const closeModalResult = new EventEmitter();
		entryComponent.clear();
		this.componentRef = entryComponent.createComponent(
			modalComponent || ModalComponent
		);

		if (options) {
			for (const [key, value] of Object.entries(options)) {
				this.componentRef.instance[key] = value;
			}
		}
		this.componentRef.instance.closeModal.subscribe((res: any) => {
			closeModalResult.emit(res);
		});

		document.body.classList.add('no-scroll');

		return closeModalResult;
	}

	public destroyModal(): void {
		document.body.classList.remove('no-scroll');
		this.componentRef.destroy();
	}
}
