import {
	Component,
	Type,
	ViewChild,
	ViewContainerRef,
	OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../core/interfaces/enums';
import { LocalStorageService } from '../../../core/services/localStorage.service';
import { EventFormComponent } from '../../modal/event/event';
import { ModalService } from '../../../core/services/modal.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.style.scss'],
})
export class HeaderComponent implements OnInit {
	@ViewChild('modalForm', { read: ViewContainerRef })
		modalForm!: ViewContainerRef;

	public isAuth = false;

	visible: boolean = false;

	isDropdownVisible: boolean = false;

	public showDialog() {
		this.visible = true;
	}

	constructor(
		protected router: Router,
		private modalService: ModalService,
		protected localStorageService: LocalStorageService
	) {}

	public setVisibleParams(): void {
		this.listNavigation.forEach((item, index) => {
			this.listNavigation[index].visible = this.isAuth;
		});
	}

	public async ngOnInit(): Promise<void> {
		const result = (await this.localStorageService.getItemLocalStorage(
			User.USER_DATA
		)) as Object;
		this.isAuth = !!Object.keys(result).length;
		this.setVisibleParams();
	}

	public listNavigation = [
		{ name: 'Cобытия', routingName: '/', visible: true },
		{ name: 'Люди', routingName: '/humans', visible: true },
		{ name: 'Профиль', routingName: '/profile', visible: true },
	];

	private openModal<C>(component: Type<C>): void {
		this.modalService.openModal(this.modalForm, undefined, component);
	}

	toggleDropdown() {
		this.isDropdownVisible = !this.isDropdownVisible;
	}
}
