import { Events } from 'src/app/core/interfaces/events';
import { signal,
	ChangeDetectionStrategy,
	Component,
	effect,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewContainerRef,
	Injector,
	input,
	model
} from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

import { WebsocketService } from 'src/app/core/services/websocket/websocket.service';
import { ModalService } from 'src/app/core/services/modal.service';
import moment from 'moment';
import { CoordinateService } from 'src/app/core/services/coordinates/coordinate.service';
import { ClockModalComponent } from '../../modal/clock-modal/clock.modal';
import { MapModalComponent } from '../../modal/map-modal/map-modal';
import { EventsMainSubClass } from '../../subclass/events-main.subclass';
import { DatePickerModalComponent } from '../../modal/date-picker-wrapper/date-picker-modal';

@Component({
	selector: 'app-event-search-filters',
	templateUrl: './event-search-filters.html',
	styleUrls: ['./event-search-filters.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSearchFiltersComponent
	extends EventsMainSubClass
	implements OnDestroy, OnInit
{
	@ViewChild('modalForm', { read: ViewContainerRef })
		modalForm!: ViewContainerRef;

	public defaultCoordinates!: any;

	public date!: string;

	public time!: string;

	@Output() eventsArr = new EventEmitter<Events.ItemParsed>();

	@Output() clearNumberPage = new EventEmitter<void>();

	public isAuth = input();

	public selectedStartDate$ = signal<moment.Moment | null>(moment());

	public selectedEndDate$ = signal<moment.Moment | null>(null);

	public isOpenMap = model<boolean>();

	public time$ = signal(60 * 24);

	public address$ = new Subject<string>();

	constructor(
		protected override wsService: WebsocketService,
		private modalService: ModalService,
		protected override coordinateService: CoordinateService,
		private injector: Injector
	) {
		super(wsService, coordinateService);

		this.setTimeMinutes();

		this.address$.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				this.coordinateService.searchCoordinateByAddress(value);
			}
		});

		this.wsService
			.on<Events.EventResponseData>()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (val) => {

					if (val.events === undefined) {
						console.log(
							'ошибка при получении событий',
							val
						);
						this.request.set(false);
						return;
					}
					this.setEvent(val);
				},
			});
	}

	ngOnInit(): void {
		this.clearPage$.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.clearNumberPage.emit();
			},
		});
		this.submitSearchEvent(true);
	}

	public setEvent(data: Events.EventResponseData): void {
		const { events, labels, total } = data;

		// if (this.page >= 1) {
		// 	this.events.update((val) => {
		// 		return {...val, data: [...val.data, ...events]}
		// 	})
		// 	return;
		// }

		this.eventsArr.emit({
			data: events,
			labels,
			total,
			error: false,
		});
	}

	public loadMoreEvents(pageNumber?: number): void {
		super.getEvents(this.typeSearch, this.selectedStartDate$()!, this.time$(), pageNumber);

	}

	public submitSearchEvent(isInitSearch? : boolean): void {
		this.clearNumberPage.emit();
		if (isInitSearch) {
			super.getEvents('recommendation', this.selectedStartDate$()!, this.time$());
			return;
		}
		super.getEvents(this.isAuth() ? 'default' : 'recommendation', this.selectedStartDate$()!, this.time$());
	}

	public visibleMap(): void {
		this.isOpenMap.set(!this.isOpenMap());
	}

	public searchByAddress(event: Event): void {
		this.address$.next((event.target as HTMLInputElement).value);
	}



	private setTimeMinutes(): void {
		effect(() => {
			if (!this.selectedEndDate$() || !this.selectedEndDate$) {
				this.time$.set(60 * 24);
				return;
			}
			const startOfDay = this.selectedStartDate$()!.startOf('day');
			const endOfDay = this.selectedEndDate$()!.endOf('day');
			const duration = moment.duration(endOfDay.diff(startOfDay));
			const minutes =  Math.round(duration.asMinutes());
			this.time$.set(minutes);
		}, {injector: this.injector, allowSignalWrites: true});
	}

	public openModal(type: string) {
		switch (type) {
		case 'time':
			this.modalService
				.openModal(this.modalForm, DatePickerModalComponent)
				.subscribe({
					next: (val: any) => {
						this.date = val.date;
						this.unixDate = moment(val.date);
					},
				});
			break;

		case 'clock':
			this.modalService
				.openModal(
					this.modalForm,
					{
						eventTime: this.timeSelectNow,
					},
					ClockModalComponent
				)
				.subscribe({
					next: (val: string) => {
						const time = val.split(':');
						this.valTime = {
							hour: time[0],
							minute: time[1],
						};
						this.timeSelectNow = val;
					},
				});
			break;

		case 'location':
			this.modalService
				.openModal(this.modalForm, undefined, MapModalComponent)
				.subscribe({
					next: (val: number[]) => {
						this.position = val;
						this.defaultCoordinates = val;
					},
				});
			break;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
