import {
	Component,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsHashService } from 'src/app/core/services/api/events/EventsHash.service';
import { EventsService } from 'src/app/core/services/api/events/events.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { LocalStorageService } from '../../../core/services/localStorage.service';
import { EventFormComponent } from '../../modal/event/event';
import { User } from '../../../core/interfaces/enums';

@Component({
	selector: 'app-event-detail',
	templateUrl: './event-detail.component.html',
	styleUrls: [
		'./event-detail.component.scss',
		'./event-detail-subscribers.component.scss',
	],
})
export class EventDetailComponent implements OnInit {
	@ViewChild('modalForm', { read: ViewContainerRef })
	public modalForm!: ViewContainerRef;

	public event: any = {};

	public timeStart: number = 0;

	public timeEnd: number = 0;

	public hash!: string;

	public decodeHash: any;

	public currentUserId!: number;

	public waitRequest = false;

	constructor(
		private route: ActivatedRoute,
		private eventHashService: EventsHashService,
		private eventService: EventsService,
		private modalService: ModalService,
		private localStorageService: LocalStorageService
	) {}

	public ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.hash = params['hash'];
		});

		this.localStorageService
			.getUserId()
			.then((userId) => {
				this.currentUserId = userId;
			})
			.catch(() => {
				this.currentUserId = 0;
			});

		this.getEvents();
	}

	public subscribeRequest(ok: boolean): void {
		if (this.waitRequest) {
			return;
		}
		this.waitRequest = true;
		this.eventService
			.subscriptionEvent(this.event.event_id, ok)
			.subscribe((response) => {
				this.waitRequest = false;
				if (!response.code) {
					if (ok) {
						// eslint-disable-next-line no-alert
						alert('Заявка на подписку отправлена.');
						this.event.sub_level = 1;
					} else {
						// eslint-disable-next-line no-alert
						alert('Заявка на отписку отправлена.');
						this.event.sub_level = 0;
					}
				} else {
					// eslint-disable-next-line no-alert
					alert('Во время выполнения запроса произошла ошибка');
				}
			});
	}

	public approveSubscribeRequest(ok: boolean, subscriber: number): void {
		if (this.waitRequest) {
			return;
		}
		this.waitRequest = true;
		this.eventService
			.approveSubscriptionEvent(this.event.event_id, ok, subscriber)
			.subscribe((response) => {
				this.waitRequest = false;
				if (!response.code) {
					if (ok) {
						// eslint-disable-next-line no-alert
						alert('Пользователь подписан.');
					} else {
						// eslint-disable-next-line no-alert
						alert('Пользователь отписан.');
					}
				} else {
					// eslint-disable-next-line no-alert
					alert('Во время выполнения запроса произошла ошибка');
				}
			});
	}

	public getEvents(): void {
		this.decodeHash = this.eventHashService.decodeEventHash(this.hash);
		this.eventService
			.getEvent(this.decodeHash[0], this.decodeHash[1])
			.subscribe({
				next: (data: any) => {
					this.event = data;
					this.timeStart = data.time_start;
					this.timeEnd = data.time_end;
				},
			});
	}
}
