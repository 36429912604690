import { Injectable } from '@angular/core';
import type { UserData } from '../interfaces/localStorage.d';
import { User } from '../interfaces/enums';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	public token = '';

	public setDataToStorage<T>(key: string, data: T): void {
		localStorage.setItem(key, JSON.stringify(data));
	}

	public setToken(token: string): void {
		this.token = token;
	}

	public async getItemLocalStorage<T>(key: string): Promise<T> {
		try {
			const result = await JSON.parse(localStorage.getItem(key) || '{}');
			return result;
		} catch (error) {
			throw new Error('not found token');
		}
	}

	public async getUserId(): Promise<number> {
		try {
			const result = await this.getItemLocalStorage<UserData>(
				User.USER_DATA
			);
			return result!.id;
		} catch (error) {
			throw new Error(`Not found userId in storage ${User.USER_DATA}`);
		}
	}

	public clear(): void {
		localStorage.clear();
	}
}
