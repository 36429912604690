<ng-template let-events let-isRecommended="isRecommended" #cards>
	<div class="card__container">

		<h3 class="card-title">События</h3>
		<div class="card-list">
			@for (item of events; track $index) {
				<app-events-item
					[item]="item"
					(onAction)="navigateToDetail(item.id, item.time_start)"
				></app-events-item>
			}
		</div>
		<p-paginator
			#paginator
			[totalRecords]="totalPage"
			[rows]="15"
			[pageLinkSize]="3"
			[alwaysShow]="false"
			(onPageChange)="loadMoreEvents()"
		>
		</p-paginator>
	</div>
</ng-template>
