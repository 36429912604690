<div class="profile-card">
	<div class="profile-card__left">
		<img class="profile-card__avatar" src="../../../../assets/images/gray.png">
	</div>
	<div class="profile-card__center">
		<h2 class="profile-card__name">{{ user.name }}</h2>
		<p class="profile-card__description">{{ user.description }}</p>
		<a href="{{ user.link }}" class="profile-card__social">Follow on Social Media</a>
		<div class="profile-card__smileys">
			<span *ngFor="let item of user.features">{{ item }}</span>
		</div>
	</div>
	<div class="profile-card__right">
		<p-button
			class="profile-icon_edit"
			icon="pi pi-pencil"
			severity="secondary"
			[outlined]="true"
			(click)="console.log();">
		</p-button>
	</div>
</div>
