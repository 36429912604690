<div class="modal" #close>
	<div class="wrapper">
		<div class="modal-header">
			<p class="modal-header__text"></p>
			<img
				src="../../../../assets/icons/modal/closeIconModal.svg"
				alt=""
				class="modal-header__close"
				(click)="destroyModal()"
			/>
		</div>
		<div class="modal-clock-picker">
			<ng-container #ref></ng-container>
		</div>
	</div>
</div>
