import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { CalendarModule } from 'primeng/calendar';
import { SvgComponent } from './components/svg/svg';
import { HeaderComponent } from './components/header/header';
import { DatePickerComponent } from './components/date-picker/date-picker';
import { MapComponent } from './components/map/map';
import { ClockComponent } from './components/clock/clock';
import { PipeModule } from './pipes/pipes.module';
import { DirectiveModule } from './directives/directive.module';
import { CardEventComponent } from './components/card-events/card-event';
import { ProfileCardComponent } from './components/profile-card/profile-card';
import { EventSearchFiltersComponent } from './components/event-search-filters/event-search-filters';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { TabFriendsComponent } from './components/tabs-profile/tab-friends/tab-friends.component';
import { TabEventsComponent } from './components/tabs-profile/tap-events/tab-events.component';
import { TabSubscribeComponent } from './components/tabs-profile/tab-subscribe/tab-subscribe.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { EventsItemComponent } from './components/events-item/events-item';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { SignInComponent } from './components/signIn/signIn.component';
import { PasswordModule } from 'primeng/password';
import { CalendarComponent } from './components/calendar/calendar.component';
import { TabViewModule } from 'primeng/tabview';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
	imports: [
		FormsModule,
		CommonModule, // Keep CommonModule for shared modules
		RouterModule,
		PipeModule,
		DirectiveModule,
		ScrollingModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		SvgComponent,
		EventsItemComponent,
		CalendarModule,
		IconFieldModule,
		InputIconModule,
		InputTextModule,
		StyleClassModule,
		DropdownModule,
		DialogModule,
		PasswordModule,
		TabViewModule,
		PaginatorModule,
	],
	declarations: [
		UserDetailComponent,
		TabFriendsComponent,
		TabEventsComponent,
		TabSubscribeComponent,
		EventDetailComponent,
		HeaderComponent,
		DatePickerComponent,
		MapComponent,
		ClockComponent,
		CardEventComponent,
		ProfileCardComponent,
		EventSearchFiltersComponent,
		SignInComponent,
		CalendarComponent
	],
	exports: [
		UserDetailComponent,
		TabFriendsComponent,
		TabEventsComponent,
		TabSubscribeComponent,
		HeaderComponent,
		DatePickerComponent,
		MapComponent,
		ClockComponent,
		DirectiveModule,
		CardEventComponent,
		CommonModule,
		ProfileCardComponent,
		EventSearchFiltersComponent,
		EventDetailComponent,
		CalendarComponent,
		EventsItemComponent,
		SignInComponent
	],
})
export class SharedModule {}
