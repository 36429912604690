import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EventsProfileSubClass } from 'src/app/shared/subclass/events-profile.subclass';
import { User } from '../../../../core/interfaces/enums';
import { EventsHashService } from '../../../../core/services/api/events/EventsHash.service';
import { WebsocketService } from '../../../../core/services/websocket/websocket.service';

@Component({
	selector: 'app-tab-events',
	templateUrl: './tab-events.component.html',
	styleUrls: ['./tab-events.component.scss'],
	providers: [WebsocketService],
})
export class TabEventsComponent
	extends EventsProfileSubClass
	implements OnInit, OnDestroy
{
	private destroy$ = new Subject<void>();

	public code!: string;

	public totalPage = 0;

	constructor(
		protected override wsService: WebsocketService,
		private router: Router,
		private eventHashService: EventsHashService,
		private route: ActivatedRoute
	) {
		super(wsService);
	}

	ngOnInit(): void {
		if (this.route.snapshot.paramMap.get('id')) {
			this.userId = Number(this.route.snapshot.paramMap.get('id'));
		} else {
			const authToken: any = localStorage.getItem(User.USER_DATA);
			const id = JSON.parse(authToken);
			this.userId = id.id;
		}
		this.getEvent();
	}

	public loadMoreEvents(): void {
		// Вызываем метод getEvent() с увеличенным значением page
		this.getEvent(this.page + 1);
	}

	public navigateToDetail(id: number, timestamp: string): void {
		const formattedTimestamp = moment(timestamp).unix();
		const code = this.eventHashService.encodeEventHash(
			id,
			formattedTimestamp
		);
		window.open(`/event/${code}`, '_blank');
	}

	ngOnDestroy(): void {
		// this.wsService.disconnect();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
