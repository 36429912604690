import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { ENV } from '@app/env';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EventsHashService } from '../../../../core/services/api/events/EventsHash.service';
import { WebsocketService } from '../../../../core/services/websocket/websocket.service';
import { User } from '../../../../core/interfaces/enums';

const { ws } = ENV;

@Component({
	selector: 'app-tab-subscribe',
	templateUrl: './tab-subscribe.component.html',
	styleUrls: ['./tab-subscribe.component.scss'],
	providers: [WebsocketService],
})
export class TabSubscribeComponent implements OnInit, OnDestroy, OnChanges {
	private destroy$ = new Subject<void>();

	public userId!: number;

	public events: any = [];

	public code!: string;

	public page = 0;

	public showBtn = false;

	private websocketSubscription: Subscription | undefined;

	public search = false;

	@Input() subscriptionUser: any = [];

	@Input() isInputData: any;

	constructor(
		private wsService: WebsocketService,
		private router: Router,
		private eventHashService: EventsHashService,
		private route: ActivatedRoute
	) {
		const authToken = localStorage.getItem(User.USER_DATA);
		if (authToken) {
			wsService.connect(
				`${ws}?type=sub&token=${JSON.parse(authToken).authToken}`
			);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (
			changes['subscriptionUser'] &&
			changes['subscriptionUser'].currentValue !== undefined &&
			!changes['subscriptionUser'].firstChange
		) {
			console.log('есть данные', this.subscriptionUser);
		}
	}

	public ngOnInit(): void {
		if (this.route.snapshot.paramMap.get('id')) {
			this.userId = Number(this.route.snapshot.paramMap.get('id'));
		} else {
			const authToken: any = localStorage.getItem(User.USER_DATA);
			const id = JSON.parse(authToken);
			this.userId = id.id;
		}
		this.getEvent();
	}

	public getEvent(page: number = 0): void {
		// Отписываемся от предыдущей подписки
		if (this.websocketSubscription) {
			this.websocketSubscription.unsubscribe();
		}

		this.wsService.send({
			type: 'sub',
			user_id: this.userId,
			page,
		});

		this.search = true;

		// Создаем новую подписку
		this.websocketSubscription = this.wsService
			.on()
			.pipe()
			.subscribe({
				next: (val: any) => {
					this.showBtn = true;

					if (val.events === undefined) {
						console.log('ошибка при получении событий', val);
						return;
					}
					if (page >= 1) {
						// Если страница больше или равна 1, добавляем новые события к уже существующим
						this.events = [...this.events, ...val.events];
					} else {
						// Если страница меньше 1, обновляем весь массив событий новыми данными
						this.events = val.events;
					}
					// Обновляем значение page в компоненте
					this.page = page;

					if (val.events.length < 15) {
						this.showBtn = false;
					}

					this.search = false;
				},
			});
	}

	public loadMoreEvents(): void {
		// Вызываем метод getEvent() с увеличенным значением page
		this.getEvent(this.page + 1);
	}

	public navigateToDetail(id: number, timestamp: string): void {
		const formattedTimestamp = moment(timestamp).unix();
		const code = this.eventHashService.encodeEventHash(
			id,
			formattedTimestamp
		);
		window.open(`/event/${code}`, '_blank');
	}

	public ngOnDestroy(): void {
		// Отписываемся от подписки при уничтожении компонента
		if (this.websocketSubscription) {
			this.websocketSubscription.unsubscribe();
		}
		this.wsService.disconnect();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
