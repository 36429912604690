<app-header></app-header>
<div class="event-card">
	<div class="head">
		<h1>
			{{ event.title }}
			<p>Публичное событие</p>
		</h1>
		<p
			*ngIf="currentUserId !== event.creator_id"
			routerLink="/profile/{{ event.creator_id }}"
		>
			{{ event.creator_name }}
		</p>
		<p *ngIf="currentUserId === event.creator_id" routerLink="/profile/">
			{{ event.creator_name }}
		</p>
	</div>
	<hr />
	<div class="description" align="justify">
		{{ event.description }}
	</div>
	<div class="info">
		<div class="time">
			<p *ngIf="timeEnd <= 0" [style.padding-top]="'1.3rem'"></p>
			<p>
				Начало:&nbsp;&nbsp;<span>{{
					timeStart * 1000 | date: 'yyyy-MM-dd HH:mm:ss'
				}}</span>
			</p>
			<p *ngIf="timeEnd > 0">
				Конец:&nbsp;&nbsp;&nbsp;&nbsp;<span>{{
					timeEnd * 1000 | date: 'yyyy-MM-dd HH:mm:ss'
				}}</span>
			</p>
		</div>
		<div class="place">
			<p>
				Место на
				<a
					href="https://yandex.ru/maps/?rtext=~{{ event.latitude }},{{event.longitude}}"
					target="_blank"
					>Яндекс&nbsp;Картах</a
				>
			</p>
		</div>
		<div *ngIf="currentUserId === event.creator_id" class="btn1">
			Редактировать
		</div>

		<div
			*ngIf="currentUserId !== event.creator_id && event.sub_level === 0"
			class="btn1"
			(click)="subscribeRequest(true)"
			[ngClass]="{ 'default-btn-disabled': waitRequest }"
		>
			Подписаться
		</div>
		<div
			*ngIf="
				currentUserId !== event.creator_id &&
				(event.sub_level === 1 || event.sub_level === 3)
			"
			class="btn1 default-btn-disabled"
		>
			Заявка отправлена
		</div>
		<div
			*ngIf="currentUserId !== event.creator_id && event.sub_level === 2"
			class="btn1"
			(click)="subscribeRequest(false)"
			[ngClass]="{ 'default-btn-disabled': waitRequest }"
		>
			Отписаться
		</div>
	</div>
</div>
<div *ngIf="currentUserId === event.creator_id">
	<div class="card-list">
		<h2>Подписчики:</h2>
	</div>
	<div
		*ngFor="let item of event.sub_users"
		class="event-subscriber"
		[ngClass]="{ success: item.sub_level === 2 }"
	>
		<p [routerLink]="['/profile', item.id]">{{ item.name }}</p>
		<div>
			<button
				*ngIf="item.sub_level !== 2"
				(click)="approveSubscribeRequest(true, item.id)"
			>
				Принять
			</button>
			<button (click)="approveSubscribeRequest(false, item.id)">
				Отклонить
			</button>
		</div>
		<div *ngIf="waitRequest" class="overlay"></div>
		<!-- Накладывающий div -->
	</div>
</div>
<div *ngIf="currentUserId !== event.creator_id">
	<div class="card-list">
		<h2>Подписчики:</h2>
	</div>
	<div class="card-list" *ngIf="event.sub_users.length > 0">
		<article
			*ngFor="let item of event.sub_users"
			class="card"
			routerLink="{{ item.id }}"
		>
			<div class="logo"></div>
			<div class="card-header card-header__center">
				<a href="#">{{ item.name }}</a>
			</div>
		</article>
	</div>
</div>

<div>
	<ng-container #modalForm></ng-container>
</div>
