import { Injectable, OnDestroy } from '@angular/core';
import {
	Observable,
	Subject,
	map,
	retry,
	timer,
	switchMap,
	throwError,
	of,
	BehaviorSubject,
} from 'rxjs';
import {
	WebSocketSubject,
	WebSocketSubjectConfig,
	webSocket,
} from 'rxjs/webSocket';
import { IWebsocketService } from '../../interfaces/websocket/websocket.interfaces';


@Injectable()
export class WebsocketService implements IWebsocketService, OnDestroy {
	private wsMessages$: Subject<MessageEvent> = new Subject();

	private wsMsQueue = null;

	private webSocket$!: WebSocketSubject<any>;

	private config!: WebSocketSubjectConfig<any>;

	public isConnected = new BehaviorSubject<boolean>(false);

	private reconnectInterval = 1000;

	private reconnectAttempts = 5;

	public status!: Observable<boolean>;

	public connect(url: string): void {
		this.config = {
			url,
			closeObserver: {
				next: () => {
					console.log('WS Disconnected');
					this.isConnected.next(false);
				},
			},
			openObserver: {
				next: () => {
					console.log('WS Connected');
					this.isConnected.next(true);
					if (this.wsMsQueue !== null) {
						this.webSocket$.next(this.wsMsQueue);
						this.wsMsQueue = null;
					}
				},
			},
			deserializer: (event: Event) => event,
		};
		let countReconnect = 0;
		this.webSocket$ = webSocket(this.config);

		this.webSocket$.pipe(retry({
			delay: () => timer(this.reconnectInterval).pipe(
				switchMap(() => {
					if (this.reconnectAttempts < countReconnect) {
						return throwError(() => new Error('Retry blocked'));
					}
					countReconnect += 1;

					return of(this.reconnectInterval);
				})
			)
		})).subscribe({
			next: (message) => {
				this.wsMessages$.next(message);
			}
		});
	}

	public on<T>(): Observable<T> {
		return this.wsMessages$.pipe(
			map((mes) => {
				try {
					return JSON.parse(mes.data);
				} catch (error) {
					console.error('Error parse message', error);
					return mes.data;
				}
			})
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public send(body: any): void {
		if (!this.isConnected.value) {
			this.wsMsQueue = body;
			return;
		}
		this.webSocket$.next(body);
	}

	public disconnect(): void {
		if (this.webSocket$) {
			this.webSocket$.complete();
			this.isConnected.next(false);
		}
	}

	public ngOnDestroy(): void {
		this.disconnect();
	}
}
