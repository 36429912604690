import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class GeoCodingService {
	public coordinates = new Subject<number[]>();

	constructor(protected httpClient: HttpClient) {}

	public run(address: string): void {
		this.httpClient.get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`).subscribe({
			next: (value) => {
				// @ts-ignore
				const { lat, lon } = value[0];
				this.coordinates.next([lat, lon]);
			}
		});
	}
}
