<div
	class="modal-block__actions"
	app-out-side-click
	(outSideClick)="isShowCalendar = false"
>
	<div class="date-picker-block">
		<input
			readonly
			class="date-picker-block__input"
			(click)="showOrHideCalendar()"
			[value]="date | timer"
		/>
	</div>
	<!-- <app-calendar
		*ngIf="isShowCalendar"
		(emitSelectedDate)="setDate($event)"
		[currentStartDate]="momentDate"
	></app-calendar> -->
</div>
