import { Directive, HostListener } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[dateMask]'
})
export class DateMaskDirective {

  @HostListener('input', ['$event'])
	onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		let value = input.value.replace(/[^0-9]/g, '');

		if (value.length > 8) {
			value = value.slice(0, 8);
		}

		if (value.length > 2) {
			value = `${value.slice(0, 2)}.${value.slice(2)}`;
		}
		if (value.length > 5) {
			value = `${value.slice(0, 5)}.${value.slice(5)}`;
		}

		input.value = value;
	}

  @HostListener('focus', ['$event'])
  onFocus(event: Event): void {
  	const input = event.target as HTMLInputElement;
  	input.value = '';
  }
}
