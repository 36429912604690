import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from '../modal-base';
import { MapComponent } from '../../components/map/map';

@Component({
	templateUrl: './map-modal.html',
	styleUrls: ['./map-modal.scss'],
})
export class MapModalComponent extends ModalComponent implements AfterViewInit {
	@ViewChild('ref', { read: ViewContainerRef }) ref!: ViewContainerRef;

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	@ViewChild('close') closeBtn!: ElementRef;

	ngAfterViewInit(): void {
		const component = this.ref.createComponent(MapComponent);
		this.cd.detectChanges();
		component.instance.closeMap.subscribe({
			next: (val: string) => {
				this.closeModal.emit(val);
			},
		});
	}

	destroyModal() {
		this.closeBtn.nativeElement.style.display = 'none';
	}
}
