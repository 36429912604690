import { ENV } from '@app/env';
import { User } from 'src/app/core/interfaces/enums';
import { Events } from 'src/app/core/interfaces/events';
import { WebsocketService } from '../../core/services/websocket/websocket.service';

const { ws } = ENV;

export class EventsProfileSubClass {
	public page = 0;

	public showBtn = false;

	public search = false;

	public events: Events.ItemWs[] = [];

	public userId!: number;

	constructor(protected wsService: WebsocketService) {
		const authToken = localStorage.getItem(User.USER_DATA);
		if (authToken) {
			wsService.connect(
				`${ws}?type=user&token=${JSON.parse(authToken).authToken}`
			);
		}
	}

	public getEvent(page: number = 0): void {
		this.sendEventRequest(page);

		this.search = true;

		this.wsService
			.on<Events.EventResponseData>()
			.pipe()
			.subscribe((val) => {
				this.showBtn = true;
				if (val.events === undefined) {
					console.log('ошибка при получении событий', val);
					return;
				}

				this.events =
					page >= 1 ? [...this.events, ...val.events] : val.events;
				this.page = page;

				this.showBtn = !(val.events.length < 15);

				this.search = false;
			});
	}

	public sendEventRequest(page: number): void {
		this.wsService.send({
			type: 'user',
			user_id: this.userId,
			page,
		});
	}
}
