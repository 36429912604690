import {
	AfterViewInit,
	Component,
	OnDestroy,
	EventEmitter,
	Output
} from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject, distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { CoordinateService } from 'src/app/core/services/coordinates/coordinate.service';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.style.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {

	private map!: L.Map;

	public readonly radius = {
		14: 1500,
		13: 3000,
		12: 6000,
		11: 9000,
		10: 12000,
		9: 30000,
	};

	public currentZoom = '14';

	private marker!: L.Marker;

	private myPositionMarker!: L.Marker;

	private position$ = new BehaviorSubject<[] | number[]>(this.coordinateService.coordinates$.value);

	private circle!: L.Circle;

	private control!: L.Control;

	private destroy$ = new Subject<void>();

	@Output() closeMap = new EventEmitter<number[] | undefined>(undefined);

	constructor(private coordinateService: CoordinateService) {
		// fix icon map
		const iconRetinaUrl = '../../../assets/images/map/marker-icon-2x.png';
		const iconUrl = '../../../assets/images/map/marker-icon.png';
		const shadowUrl = '../../../assets/images/map/marker-shadow.png';
		const iconDefault = L.icon({
			iconRetinaUrl,
			iconUrl,
			shadowUrl,
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			tooltipAnchor: [16, -28],
			shadowSize: [41, 41],
		});
		L.Marker.prototype.options.icon = iconDefault;
	}

	public ngAfterViewInit(): void {
		this.initMap(this.coordinateService.coordinates$.value).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.coordinateService.coordinates$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe({
					next: (value) => {
						if (value) {
							this.position$.next(value);
							this.map.setView(value as L.LatLngExpression);
							this.addMyPositionMarker();
						}
					}
				});
			}
		});

	}

	private initMap(coordinates: number[]): Observable<void> {
		return new Observable<void>((subscriber) => {
			this.map = L.map('map', {
				center: coordinates as L.LatLngExpression,
				zoom: 14,
				attributionControl: false,
			});

			const tiles = L.tileLayer(
				'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				{
					maxZoom: 14,
					minZoom: 3,
					attribution:
						'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				}
			);

			tiles.addTo(this.map);
			this.eventListenerClickMap();
			this.getRadius();
			this.listenZoom();
			subscriber.next();
			subscriber.complete();
		});
	}

	private eventListenerClickMap(): void {
		this.map!.on('click', ({ latlng }) => {
			const { lat, lng } = latlng;
			this.coordinateService.coordinates$.next([lat, lng]);
			if (this.marker) {
				this.map!.removeLayer(this.marker);
			}
		});
	}

	private addMarker(coordinates: number[], message = ''): void {
		this.marker = L.marker(coordinates as L.LatLngExpression);
		this.marker.addTo(this.map!).bindPopup(message).openPopup();
	}

	private addMyPositionMarker(): void {
		if (this.myPositionMarker) {
			this.myPositionMarker.remove();
		}

		this.myPositionMarker = L.marker(this.position$.value as L.LatLngExpression);
		this.myPositionMarker.addTo(this.map!).openPopup();
		this.getRadius();
	}

	private getRadius(): void {
		// @ts-ignore
		const zoom = this.radius[this.currentZoom];

		if (!this.circle) {
			this.circle = L.circle(
				this.position$.value as L.LatLngExpression,
				{
					radius: zoom,
				}
			);
			this.coordinateService.radius = zoom;
			this.circle.addTo(this.map);
			this.control = L.control
				.scale({
					metric: true,
					imperial: false,
				})
				.addTo(this.map);
			return;
		}

		try {
			this.circle.setLatLng(
				this.position$.value as L.LatLngExpression
			);
			if (parseInt(this.currentZoom, 10) <= 9) {
				return;
			}
			this.circle.setRadius(zoom);
			this.coordinateService.radius = zoom;
		} catch (error) {
			console.log(this.currentZoom);
		}
	}

	private listenZoom(): void {
		this.map.addEventListener('zoom', () => {
			this.currentZoom = this.map.getZoom().toString();
			this.getRadius();
		});
	}

	public submitPosition(): void {
		this.closeMap.emit(this.position$.value);
	}

	public ngOnDestroy(): void {
		this.position$.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
